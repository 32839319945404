import NextLink from 'next/link';
import React from 'react';
import clsx from 'clsx';
import { Text, Video, Image, RawHtml, AlignContent, ModuleContainer, Button } from 'ui';
import { breakpoints, sizes } from '~/constants';
import { CmsModel } from 'api-types';
import styles from './M75MediaText.module.css';

export type M75MediaTextProps = CmsModel.M075MediaAndTextModule;

export const M75MediaText = ({
    image,
    video,
    headline,
    subHeadline,
    text,
    callToAction,
    mediaHorizontalAlignment = 'left',
    ...rest
}: M75MediaTextProps) => {
    const shouldDisplayImage = video && video.length > 0 ? false : true;

    return (
        <ModuleContainer {...rest} hasPadding={true} >
            <div >
                <div className={clsx(styles.m75)} style={{ maxWidth: sizes.contentMaxWidth }}>
                    <div
                        className={clsx(
                            styles.m75Content,
                            styles[`media-align-${mediaHorizontalAlignment}`],
                            {
                                [styles.mediaAlignLeft]: mediaHorizontalAlignment === 'left',
                                [styles.mediaAlignRight]: mediaHorizontalAlignment === 'right',

                            }
                        )}
                    >
                        <AlignContent alignment="left" position="center" >
                            <div>
                                {subHeadline ? (
                                    <Text fontWeight='regular' as='h3' variant="bodyExtraSmall" children={subHeadline} />
                                ) : null}
                                {headline ? (
                                    <Text as='h2' fontWeight='regular' variant="display3" >
                                        {headline}
                                    </Text>)
                                    : null}
                            </div>
                            {text ? <RawHtml html={text} /> : null}
                            {callToAction && (
                                <NextLink href={callToAction.url} passHref>
                                    <Button
                                        variant="link"
                                        iconPosition="right"
                                        iconName="arrow-right"
                                        target={callToAction?.target}
                                        title={callToAction?.title}
                                        className={styles.linkOverride}
                                        draggable={false}
                                    >
                                        <h4>{callToAction?.title}</h4>
                                    </Button>
                                </NextLink>
                            )}
                        </AlignContent>
                        <div className={clsx(styles.gridAreaMedia)}>
                            {shouldDisplayImage && image ? (
                                <Image
                                    {...image}
                                    layout="responsive"
                                    sizes={`(max-width: ${breakpoints.xs}px) 100vw, ${sizes.contentMaxWidth} 50vw, 720px`}
                                />
                            ) : null}
                            {!shouldDisplayImage && video ? (
                                <Video controls={true} src={video} posterSrc={image?.src} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </ModuleContainer>
    );
};
